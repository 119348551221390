import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { ToastService } from '../../../services/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly toastService: ToastService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const expectedRoles: string[] = route.data['roles'];
    const userRoles = this.authService.getUserRoles();

    if (userRoles && expectedRoles.some((role) => userRoles.includes(role))) {
      return true;
    }

    this.toastService.error('No tienes permisos para acceder a esta página');
    this.router.createUrlTree(['/']);
    return false;
  }
}
