import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface Toast {
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly toastSubject = new Subject<Toast>();
  toasts$ = this.toastSubject.asObservable();

  showToast(type: 'success' | 'error' | 'warning' | 'info', message: string): void {
    this.toastSubject.next({ type, message });
  }

  error(message: string): void {
    this.showToast('error', message);
  }

  success(message: string): void {
    this.showToast('success', message);
  }

  warning(message: string): void {
    this.showToast('warning', message);
  }

  info(message: string): void {
    this.showToast('info', message);
  }
}
