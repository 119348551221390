import { Routes } from '@angular/router';
import { AuthGuard } from './core/auth/guards/auth/auth.guard';
import { RoleGuard } from './core/auth/guards/role/role.guard';
import { Roles } from './core/enums/roles.enum';
import { AuthenticatedGuard } from './core/auth/guards/authenticated/authenticated.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./shared/components/layout/layout.component'),
    children: [
      // HOME
      {
        path: '',
        loadComponent: () => import('./shared/components/home/home.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial, Roles.Usuario] },
      },
      // CLIENTES
      {
        path: 'clientes',
        loadComponent: () => import('./clientes/components/clientes/clientes.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial, Roles.Usuario] },
      },
      {
        path: 'clientes/:id',
        loadComponent: () => import('./clientes/components/cliente/cliente.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial, Roles.Usuario] },
      },
      // SUMINISTROS
      {
        path: 'suministros',
        loadComponent: () => import('./suministros/components/suministros/suministros.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial, Roles.Usuario] },
      },
      {
        path: 'suministros/:id',
        loadComponent: () => import('./suministros/components/suministro/suministro.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial, Roles.Usuario] },
      },
      // CONTRATOS
      {
        path: 'contratos',
        loadComponent: () => import('./contratos/components/contratos/contratos.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial, Roles.Usuario] },
      },
      {
        path: 'contratos/:id',
        loadComponent: () => import('./contratos/components/contrato/contrato.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial, Roles.Usuario] },
      },
      {
        path: 'registro-contrato',
        loadComponent: () => import('./contratos/components/registro-contrato/registro-contrato.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      // FACTURAS
      {
        path: 'contrato/:idContrato/nueva-factura',
        loadComponent: () =>
          import('./facturas/components/nueva-factura/nueva-factura.component').then((m) => m.NuevaFacturaComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      // COMERCIALES
      {
        path: 'comerciales',
        loadComponent: () => import('./comerciales/components/comerciales/comerciales.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'comerciales/nuevo',
        loadComponent: () => import('./comerciales/components/nuevo-comercial/nuevo-comercial.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin] },
      },
      {
        path: 'comerciales/:id',
        loadComponent: () => import('./comerciales/components/comercial/comercial.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      // USUARIOS
      {
        path: 'usuarios',
        loadComponent: () => import('./usuarios/components/usuarios/usuarios.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial, Roles.Usuario] },
      },
      {
        path: 'usuarios/:id',
        loadComponent: () => import('./usuarios/components/usuario/usuario.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial, Roles.Usuario] },
      },
      // MAESTROS
      {
        path: 'maestros',
        loadComponent: () => import('./maestros/components/dashboard/dashboard.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/estados-comision',
        loadComponent: () => import('./maestros/components/estado-comision/estado-comision.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/estados-compra',
        loadComponent: () => import('./maestros/components/estado-compra/estado-compra.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/tipo-documento',
        loadComponent: () => import('./maestros/components/tipo-documento/tipo-documento.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/tipo-iva',
        loadComponent: () => import('./maestros/components/tipo-iva/tipo-iva.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/tipo-via',
        loadComponent: () => import('./maestros/components/tipo-via/tipo-via.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/tipo-lectura',
        loadComponent: () => import('./maestros/components/tipo-lectura/tipo-lectura.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/estado-contrato',
        loadComponent: () => import('./maestros/components/estado-contrato/estado-contrato.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/empresa-facturadora',
        loadComponent: () => import('./maestros/components/empresa-facturadora/empresa-facturadora.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/canales',
        loadComponent: () => import('./maestros/components/canal/canal.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/tarifas',
        loadComponent: () => import('./maestros/components/tarifa/tarifa.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/proveedores',
        loadComponent: () => import('./maestros/components/proveedor/proveedor.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/impuestos',
        loadComponent: () => import('./maestros/components/impuesto/impuesto.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/modalidades',
        loadComponent: () => import('./maestros/components/modalidad/modalidad.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
      {
        path: 'maestros/estado-rentabilidad',
        loadComponent: () => import('./maestros/components/estado-rentabilidad/estado-rentabilidad.component'),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: [Roles.SuperAdmin, Roles.Comercial] },
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () => import('./core/components/login/login.component'),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
